<template>
			<div class="card">
				<h1>За що ми полюбили крафт?</h1>
                <ul>
                    <li><p>Традиції українського ремісництва напоїв</p></li>
                    <li><p>Локальний продукт дає змогу відчути смак місцевості</p></li>
                    <li><p>Виключно натуральні складові, жодних штучних домішок</p></li>
                    <li><p>Індивідуальна рецептура, маленькі об'єми та обмеженість партій</p></li>
                    <li><p>У кожного напою є автор, інакше його зробив ніхто</p></li>
                </ul>

                <div class="contacts">
                <p>Телефон для довідок: <span style="white-space: nowrap;"><b> +38 (067) 751 11 10</b></span></p>
                <p>Адреса електронної пошти: <b>craft.spirits.ua@gmail.com</b></p>
                </div>
			</div>

            
</template>

<script>
export default {

}
</script>

<style scoped>
.card {
    padding-left: 30px;
}

.card ul li{
    list-style-type: circle;
    font-size: 18px;
}    

.contacts {
    font-size: 18px;
    padding-top: 40px;
}
</style>